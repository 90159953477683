import type { RecursivePartial } from '#types/utils'
import type { PdpConfig } from '#types/config/pages/pdp'

export default {
  benefitRatingsMaxScale: 3,
  brandClasses: {
    ctasWrapper: 'b-t b-grey-70 pb-8 pt-4 space-y-4',
    eSpotAboveAccordions: 'mb-6',
    eSpotAboveAddToCart: 'mt-4 [&_.bg-brand-6]:w-full',
    eSpotLazyAboveFooter: 'pb-20 pt-12 <md:(b-t b-grey-70) [&_.container]:px-0',
    eSpotLazyBelowGallery: '<md:pb-8',
    headingProductName: 'title-2',
    klarnaPayments: 'mt-4',
    paypalPayments: 'mt-4',
    reviewsHeading: 'title-2 text-center',
    reviewsSearchSubmit: 'rounded-r-sm bg-brand-2 c-white p-1',
    reviewsSearchWrapper: 'bg-grey-90 p-4 text-center lg:(flex items-center between) <md:-mx-4',
    reviewsSnapshotWrapper: 'grid gap-4',
    reviewsWrapper: 'text-sm space-y-6',
    shopTheLookButton: 'uppercase fw-bold',
    variantSelectionWrapper: 'b-t b-grey-70 pt-4 space-y-4'
  },
  details: {
    ctaSize: {
      sm: 'sm',
      lg: 'md'
    },
    ctaVariant: 'tertiary',
    favoriteCta: {
      iconSize: 'md',
      iconOnly: false,
      variantSelected: 'tertiary-inverse'
    },
    findInStoreCta: {
      showIcon: true
    }
  },
  gallery: {
    loop: true,
    presets: {
      src: {
        width: 650,
        height: 650
      },
      srcZoom: {
        width: 1300,
        height: 1300
      }
    }
  },
  questions: {
    pageSize: 6
  },
  quickshop: {
    dialogOptions: {
      type: { sm: 'panel', md: 'modal' }
    }
  },
  reviews: {
    dialogOptions: {
      reviews: { type: { sm: 'panel', md: 'modal' } },
      reviewWrite: { type: { sm: 'panel', md: 'modal' } },
      filters: { type: 'panel' }
    },
    filterOptionsMaxNumber: 7,
    iconSize: 'lg',
    mostLikedCommentTextThreshold: 225,
    nonFilterKeys: ['cons', 'pros'],
    pageSize: 6,
    prosAndConsMaxNumber: 6,
    recommendationRateThreshold: 74,
    showFilters: true,
    showRatingBreakdown: true,
    showWriteReviewCta: true
  },
  shopTheLook: {
    dialogOptions: { type: { sm: 'panel', md: 'modal' } }
  },
  showAddToCartCta: true,
  sizeAndFit: {
    showFitRating: true,
    showHeading: true,
    showModelMeasurements: true,
    showSizeChartIcon: true
  },
  sizeChart: {
    dialogOptions: { type: { sm: 'panel', md: 'modal' } }
  },
  swatches: {
    collapseThreshold: 24,
    size: { sm: 'sm', lg: 'md' },
    variant: 'round'
  },
  tryItOn: {
    dialogOptions: { type: { sm: 'panel', md: 'modal' } }
  },
  upsells: {
    showButton: false,
    showCheckbox: true,
    showDetails: true
  }
} satisfies RecursivePartial<PdpConfig>
