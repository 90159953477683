<template>
  <label
    :id
    :class="$props.class"
    class="has-[:focus-visible]:outline-offset-2 has-[:focus-visible]:outline-auto"
    style="outline-color: -webkit-focus-ring-color;"
    :data-test-id="dataTestId || 'base-radio'"
  >
    <input
      v-model="model"
      type="radio"
      class="peer sr-only"
      v-bind="{ ...props, ...$attrs }"
      @change="$emit('change', ($event.target as HTMLInputElement)?.value)"
    >
    <slot />
  </label>
</template>

<script lang="ts" setup>
import type { CSSClass } from '#types/common'

defineOptions({ inheritAttrs: false })

const props = defineProps<{
  value?: string | number
  name?: string
  id?: string
  class?: CSSClass
  dataTestId?: string
}>()

defineEmits<{ change: [value: string] }>()

const model = defineModel<string | number>()
</script>
