import componentsCart from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/cart"
import componentsCheckoutApplePayButton from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/checkout/applePayButton"
import componentsCheckoutShippingAndDelivery from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/checkout/shippingAndDelivery"
import componentsCmsHero from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/cms/hero"
import componentsCmsImageBanner from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/cms/imageBanner"
import componentsCmsMegaMenu from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/cms/megaMenu"
import componentsCmsMegaMenuL1Desktop from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/cms/megaMenuL1Desktop"
import componentsCmsMegaMenuL2Desktop from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/cms/megaMenuL2Desktop"
import componentsDialogFilters from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/dialog/filters"
import componentsDialogForgotPassword from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/dialog/forgotPassword"
import componentsDialogQuickshop from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/dialog/quickshop"
import componentsDialogSignIn from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/dialog/signIn"
import componentsFormForgotPassword from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/form/forgotPassword"
import componentsFormSignIn from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/form/signIn"
import componentsFormSignUp from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/form/signUp"
import componentsPaypalButton from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/paypal/button"
import componentsProductAccordions from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/product/accordions"
import componentsProductBadge from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/product/badge"
import componentsProductCard from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/product/card"
import componentsProductList from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/product/list"
import componentsProductPagination from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/product/pagination"
import componentsProductUpsell from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/product/upsell"
import componentsVfButton from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/button"
import componentsVfColorPicker from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/colorPicker"
import componentsVfDropdown from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/dropdown"
import componentsVfFooter from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/footer"
import componentsVfFormSelect from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/form/select"
import componentsVfHeader from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/header"
import componentsVfLoyalty from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/loyalty"
import componentsVfMediaGallery from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/mediaGallery"
import componentsVfPanel from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/panel"
import componentsVfPill from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/pill"
import componentsVfSearch from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/search"
import componentsVfSizePicker from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/sizePicker"
import index from "/opt/build/repo/brands/timberland/regions/nora/config/app/index"
import pagesCms from "/opt/build/repo/brands/timberland/regions/nora/config/app/pages/cms"
import pagesPdp from "/opt/build/repo/brands/timberland/regions/nora/config/app/pages/pdp"
import pagesPlp from "/opt/build/repo/brands/timberland/regions/nora/config/app/pages/plp"
    
export default {...index,"components":{"cart":componentsCart,"checkout":{"applePayButton":componentsCheckoutApplePayButton,"shippingAndDelivery":componentsCheckoutShippingAndDelivery},"cms":{"hero":componentsCmsHero,"imageBanner":componentsCmsImageBanner,"megaMenu":componentsCmsMegaMenu,"megaMenuL1Desktop":componentsCmsMegaMenuL1Desktop,"megaMenuL2Desktop":componentsCmsMegaMenuL2Desktop},"dialog":{"filters":componentsDialogFilters,"forgotPassword":componentsDialogForgotPassword,"quickshop":componentsDialogQuickshop,"signIn":componentsDialogSignIn},"form":{"forgotPassword":componentsFormForgotPassword,"signIn":componentsFormSignIn,"signUp":componentsFormSignUp},"paypal":{"button":componentsPaypalButton},"product":{"accordions":componentsProductAccordions,"badge":componentsProductBadge,"card":componentsProductCard,"list":componentsProductList,"pagination":componentsProductPagination,"upsell":componentsProductUpsell},"vf":{"button":componentsVfButton,"colorPicker":componentsVfColorPicker,"dropdown":componentsVfDropdown,"footer":componentsVfFooter,"form":{"select":componentsVfFormSelect},"header":componentsVfHeader,"loyalty":componentsVfLoyalty,"mediaGallery":componentsVfMediaGallery,"panel":componentsVfPanel,"pill":componentsVfPill,"search":componentsVfSearch,"sizePicker":componentsVfSizePicker}},"pages":{"cms":pagesCms,"pdp":pagesPdp,"plp":pagesPlp}}
