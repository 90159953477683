import type { RecursivePartial } from '#types/utils'
import type { DialogConfig } from '#types/config/components/dialog'

export default {
  brandClasses: {
    findInStoreForm: 'space-y-4'
  },
  myLocationButtonVariant: 'secondary',
  cancelButtonVariant: 'tertiary',
  dialogOptions: {
    type: {
      sm: 'panel',
      md: 'modal'
    }
  }

} satisfies RecursivePartial<DialogConfig['pickupInStore']>
