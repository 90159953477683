import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    content: {
      x: 'w-100'
    }
  },
  maxPanelSize: {
    x: 'calc(100% - 3.9rem)',
    y: '100dvh'
  }
} satisfies RecursivePartial<VfConfig['panel']>
