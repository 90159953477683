import type { RecursivePartial } from '#types/utils'
import type { DialogConfig } from '#types/config/components/dialog'

export default {
  brandClasses: {
    dialogContent: 'md:w-121 lg:w-248',
    findInStoreForm: 'lg:(flex items-center between) space-y-4'
  },
  cancelButtonVariant: 'secondary'
} satisfies RecursivePartial<DialogConfig['pickupInStore']>
